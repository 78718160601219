import React, { useCallback, useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import _ from 'lodash'

import { GET_GROUPS_DROPDOWN } from 'Queries/groupsQueries'
import FormComponent from 'Helpers/form'
import PropTypes from 'prop-types'

const GroupSelect = ({ additionalOptions, selectedOptions, onGroupChangeHandler, ...props }) => {
	const [loadGroups, { error, data }] = useLazyQuery(GET_GROUPS_DROPDOWN, {
		fetchPolicy: 'network-only',
	})

	const debounceSearch = useCallback(
		_.debounce(query => {
			onGroupChangeHandler([], true)
			loadGroups({ variables: { search: query } })
		}, 300),
		[],
	)
	useEffect(() => {
		if (data) {
			onGroupChangeHandler(data.dropdown, false)
		}
	}, [data])
	const handleSearchChange = useCallback((e, { searchQuery }) => {
		debounceSearch(searchQuery)
	}, [])

	const options = useMemo(() => {
		const array = _.map(data ? data.dropdown : [], ({ id, name }) => ({
			key: id,
			value: id,
			text: name,
		}))
			.concat(additionalOptions)
			.concat(selectedOptions)
		return [...new Set(array)]
	}, [additionalOptions, selectedOptions, data])

	useEffect(() => {
		// eslint-disable-next-line react/prop-types
		loadGroups({ variables: { require_id: props.value } })
	}, [selectedOptions])

	if (error) return null

	return (
		<FormComponent
			label="group"
			name="group"
			type="dropdown"
			options={options}
			placeholder="Group"
			icon="angle down"
			selection
			search
			onSearchChange={handleSearchChange}
			clearable
			{...props}
		/>
	)
}

GroupSelect.propTypes = {
	additionalOptions: PropTypes.arrayOf(PropTypes.object),
	selectedOptions: PropTypes.array,
	onGroupChangeHandler: PropTypes.func,
}

GroupSelect.defaultProps = {
	additionalOptions: [],
	selectedOptions: [],
	onGroupChangeHandler: () => {},
}

export { GroupSelect }
