import gql from 'graphql-tag'

export const GET_MANAGER_DASHBOARD = gql`
	query dashboard($limit: Int) {
		manager(limit: $limit) {
			nearing_completion
			learners_overdue
			learners_in_progress
			compliance_percentage
		}
	}
`

export const GET_LEARNER_DASHBOARD = gql`
	query dashboard {
		learner {
			total_current
			total_planned
			total_completed
			total_optional
		}
	}
`

export const GET_USER_ROLES = gql`
	query users($id: Int!) {
		byId(id: $id) {
			key
			id
			currentAccount {
				supervision_frequency
				appraisal_frequency
				performance_management
				roles {
					role
				}
			}
			groups {
				name
				role
			}
		}
	}
`

export const GET_USER_INFO = gql`
	query users($id: Int!) {
		byId(id: $id) {
			id
			currentAccount {
				supervision_frequency
				appraisal_frequency
				performance_management
				name
				is_account_owner
				is_zoho_connected
			}
			groups {
				name
				role
			}
			lastViewedSubject {
				id
				pivot_id
				name
				description
				short_description
				img
				target_completion
				progress
				obs_course_id
				last_viewed {
					id
					name
					obs_module_id
				}
			}
		}
	}
`
