import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Button } from 'semantic-ui-react'
import _ from 'lodash'

import { BOOK_MEETING } from 'Mutations/meetingMutations'
import { useForm } from 'Hooks'
import { withContext } from 'Context/index'
import FormComponent from 'Helpers/form'
import moment from 'moment'

const validate = values => {
	const errors = {}
	const requiredFields = ['learner_id', 'meeting_date', 'type']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	return errors
}

const BookMeetingModal = ({ open, initialValues, onClose, loadManagerMeetings, query }) => {
	const [sending, setSending] = useState(false)
	const [bookMeeting] = useMutation(BOOK_MEETING)
	const {
		values: { learner_id, meeting_date, type },
		errors,
		handleSubmit,
		handleSelectChange,
	} = useForm(
		() => {
			setSending(true)

			const variables = {
				payload: {
					learner_id,
					meeting_date,
					type,
				},
			}
			if (initialValues && initialValues.id) {
				variables.payload.id = initialValues.id
			}
			bookMeeting({ variables })
				.then(() => {
					loadManagerMeetings({
						variables: {
							search: query,
						},
					})
				})
				.finally(() => {
					setSending(false)
					onClose()
				})
		},
		validate,
		initialValues,
	)
	const typeOptions = [
		{
			key: 'Appraisal',
			value: 'Appraisal',
			text: 'Appraisal',
		},
		{
			key: 'Supervision',
			value: 'Supervision',
			text: 'Supervision',
		},
	]

	const handlingErrors = !_.isEmpty(initialValues)
	console.log('handlingErrors', handlingErrors)
	const modalContent = (
		<form onSubmit={handleSubmit}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
				<FormComponent.Date
					name="meeting_date"
					value={meeting_date || null}
					placeholder="Meeting Date"
					icon="calendar alternate outline"
					dateFormat="DD-MM-YYYY"
					clearIcon="close"
					label="Meeting Date"
					clearable
					closable
					minDate={moment()}
					error={errors.meeting_date}
					onChange={handleSelectChange}
				/>
				<FormComponent.Dropdown
					label="Type"
					name="type"
					options={typeOptions}
					onChange={handleSelectChange}
					icon="angle down"
					selection
					placeholder="Meeting Type"
					selectOnBlur={false}
					error={errors.type}
					fluid
					value={type || null}
				/>
			</div>
			<div className="actions">
				<Button type="button" content="Cancel" disabled={sending} onClick={onClose} />
				<Button type="submit" content="Save" loading={sending} />
			</div>
		</form>
	)
	return (
		<Modal className="create-update-group-modal" open={open} size="tiny" onClose={onClose}>
			<Modal.Header>{`${initialValues.id ? 'Edit' : 'Add'} Booking`}</Modal.Header>
			<Modal.Content>{modalContent}</Modal.Content>
		</Modal>
	)
}

BookMeetingModal.propTypes = {
	open: PropTypes.bool,
	initialValues: PropTypes.object,
	onClose: PropTypes.func,
	loadManagerMeetings: PropTypes.func.isRequired,
	query: PropTypes.string,
}

BookMeetingModal.defaultProps = {
	open: false,
	initialValues: {},
	onClose: () => {},
	query: null,
}

export default withContext(
	([
		{
			user: { user },
		},
	]) => ({
		user,
	}),
	BookMeetingModal,
)
