import gql from 'graphql-tag'

export const BOOK_MEETING = gql`
	mutation performance($payload: BookMeetingInputs!) {
		bookMeeting(payload: $payload) {
			id
			type
			meeting_date
			completed_date
			learner_sign_off_date
			manager_sign_off_date
		}
	}
`

export const SAVE_NOTES = gql`
	mutation performance($payload: SaveMeetingNotesInputs!) {
		saveMeetingNotes(payload: $payload) {
			id
			type
			meeting_date
			completed_date
			learner_sign_off_date
			manager_sign_off_date
		}
	}
`
