import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'Context/index'
import withBreadcrumbs from '../../../../common/Breadcrumbs/withBreadcrumbs'
import MeetingAgendaItem from './MeetingAgendaItem'

const MeetingAgendaItems = ({ activeAgendas, onUpdateAgendaComments }) => {
	const agendaCommentsRef = useRef([])
	useEffect(() => {
		agendaCommentsRef.current = [...activeAgendas]
	}, [activeAgendas])
	const agendaComments = useMemo(() => {
		return [...activeAgendas]
	}, [activeAgendas])
	const updateAgendaComment = useCallback(
		(index, type, value) => {
			const newData = [...agendaCommentsRef.current]
			if (newData.length > index && newData[index].note) {
				newData[index].note[type] = value
				onUpdateAgendaComments(newData)
			}
		},
		[onUpdateAgendaComments],
	)
	return (
		<>
			<div className="meeting-agenda-width">
				{agendaComments.length > 0 &&
					agendaComments.map((res, index) => (
						<div key={res.id} className="meeting-agenda-title">
							<div className="meeting-agenda-1">
								<p>{res.item_title || ''}</p>
							</div>
							<div className="meeting-agenda-input">
								<div className="meeting-agenda-header">
									<h5>Description:</h5>
									<p>{res.item_text || ''}</p>
								</div>
								<MeetingAgendaItem
									agendaItem={res}
									index={index}
									onCommentUpdate={updateAgendaComment}
								/>
							</div>
						</div>
					))}
			</div>
		</>
	)
}

MeetingAgendaItems.propTypes = {
	activeAgendas: PropTypes.array.isRequired,
	onUpdateAgendaComments: PropTypes.func.isRequired,
}
export default withContext(contextData => {
	return {
		userRole: contextData[0].user.user.account.role,
		userRoleType: contextData[0].user.user.account.roleType,
	}
}, withBreadcrumbs(MeetingAgendaItems))
