import React from 'react'
import PropTypes from 'prop-types'
import { PageContent } from 'Common'
import ManagerMeeting from './ManagerMeeting'

const Table = ({ performance, isReviewer }) => {
	if (!performance.length) return <PageContent.Message message="List is empty" />
	return (
		<div>
			<div className="list-header">
				{!isReviewer && <div />}
				<div />
				<div>
					<div className="meeting-table-head">
						<span>Type</span>
					</div>
					<div className="meeting-table-head">
						<span>Meeting Date</span>
					</div>
					<div className="meeting-table-head">
						<span></span>
					</div>
				</div>
			</div>
			{performance.length > 0 && performance.map(item => <ManagerMeeting key={item.id} item={item} />)}
		</div>
	)
}

Table.propTypes = {
	performance: PropTypes.array.isRequired,
	isReviewer: PropTypes.bool.isRequired,
}
export default Table
