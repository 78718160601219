import gql from 'graphql-tag'

export const GET_USER_BY_ID = gql`
	query users($id: Int!) {
		byId(id: $id) {
			id
			first_name
			last_name
			photo
			email
			secondary_email
			username
			account {
				id
				manager {
					id
					first_name
					last_name
				}
			}
			currentAccount {
				name
				role
				is_active
				uid
			}
			groups {
				id
				name
				role
			}
		}
	}
`
