import React from 'react'
import PropTypes from 'prop-types'
import { PageContent, Table, CustomPaginator } from 'Common'
import PerformanceTableData from './PerformanceTableData'
// import UserItem from './UserItem'

const PerformanceTable = ({
	userList,
	page,
	setPage,
	totalCount,
	length,
	perPage,
	activeSortParam,
	handleSortTable,
}) => {
	if (!userList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Manager"
								sortParam="managerName"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Group"
								sortParam="groupName"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>Role</div>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>Last Supervision</div>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>Next Supervision</div>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>Last Appraisal</div>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>Next Appraisal</div>
						</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{userList.map(user => (
						<PerformanceTableData key={user.id} user={user} />
					))}
				</Table.Body>
			</Table>
			<CustomPaginator
				totalCount={totalCount}
				perPage={perPage}
				pageChangeHandler={setPage}
				length={length}
				activePage={page}
			/>
		</>
	)
}

PerformanceTable.propTypes = {
	userList: PropTypes.array.isRequired,
	length: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
}

PerformanceTable.defaultProps = {
	activeSortParam: null,
}

export default PerformanceTable
