import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Button } from 'semantic-ui-react'
import _ from 'lodash'

import { UPDATE_CREATE_FREQUENCY } from 'Mutations/frequencyMutations'
import { useForm } from 'Hooks'
import { withContext } from 'Context/index'
import { profileUpdateAction } from 'Actions/profileActions'
import FormComponent from 'Helpers/form'

const validate = values => {
	const errors = {}
	const requiredFields = ['frequency']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	return errors
}

const CreateUpdateFrequency = ({ open, initialValues, onClose, user, updateProfile }) => {
	const [sending, setSending] = useState(false)
	const [updateCreateFrequency] = useMutation(UPDATE_CREATE_FREQUENCY)

	const {
		values: { type, frequency },
		errors,
		handleSubmit,
		handleSelectChange,
	} = useForm(
		() => {
			setSending(true)

			const variables = {
				payload: {
					frequency,
					type,
				},
			}

			updateCreateFrequency({ variables })
				.then(
					({
						data: {
							updateFrequency: { appraisal_frequency, supervision_frequency },
						},
					}) => {
						if (user) {
							user.account.appraisal_frequency = appraisal_frequency
							user.account.supervision_frequency = supervision_frequency
							updateProfile(user)
						}
					},
				)
				.finally(() => {
					setSending(false)
					onClose()
				})
		},
		validate,
		initialValues,
	)
	const frequencyOptions = []
	for (let i = 1; i <= 24; i++) {
		frequencyOptions.push({
			key: i,
			value: i,
			text: i,
		})
	}

	const handlingErrors = !_.isEmpty(initialValues)
	console.log('handlingErrors', handlingErrors)
	const frequencyLabel = type + ' Frequency in Months'
	const modalContent = (
		<form onSubmit={handleSubmit}>
			<FormComponent.Dropdown
				label={frequencyLabel}
				name="frequency"
				options={frequencyOptions}
				onChange={handleSelectChange}
				icon="angle down"
				selection
				selectOnBlur={false}
				sort={false}
				error={errors.frequency}
				fluid
				value={frequency || null}
			/>
			<div className="actions">
				<Button type="button" content="Cancel" disabled={sending} onClick={onClose} />
				<Button type="submit" content="Save" loading={sending} />
			</div>
		</form>
	)

	return (
		<Modal className="create-update-group-modal" open={open} size="mini" onClose={onClose}>
			<Modal.Header>{`${!_.isEmpty(initialValues) ? 'Edit' : 'Add'} Frequency`}</Modal.Header>
			<Modal.Content>{modalContent}</Modal.Content>
		</Modal>
	)
}

CreateUpdateFrequency.propTypes = {
	open: PropTypes.bool,
	initialValues: PropTypes.object,
	onClose: PropTypes.func,
	user: PropTypes.object.isRequired,
	updateProfile: PropTypes.func,
}

CreateUpdateFrequency.defaultProps = {
	open: false,
	initialValues: {},
	onClose: () => {},
	updateProfile: () => {},
}

export default withContext(
	([
		{
			user: { user },
		},
		dispatch,
	]) => ({
		user,
		updateProfile: data => profileUpdateAction(data, dispatch),
	}),
	CreateUpdateFrequency,
)
