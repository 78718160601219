import React, { useState, useEffect } from 'react'
import { Button, Loader, Tab } from 'semantic-ui-react'

import AddSubject from 'Modals/users/AddSubject'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'
import { withContext } from 'Context/index'
import { getLearnerDetails, getAvailableForGroupSubjects } from 'Actions/usersActions'
import { Routes } from 'Constants'
import { GET_USER_SESSION_BY_ID } from 'Queries/settingQueries'
import { allRoles, isReviewerRole, isManagerRole } from 'Utils/roles'
import { GET_USER_PERFORMANCE_MEETING } from 'Queries/performanceQueries'
import _ from 'lodash'
import { GET_USER_SUBJECTS_BY_ID } from './queries'
import Table from './Table'
import SessionTable from './SessionTable'
import PerformanceMeeting from '../../../Performance/Meeting'
import BookMeetingModal from '../../../../modals/performance/BookMeetingModal'
import { compare } from '../../../../../utils'
import UserLearningArchive from '../UserLearningArchive'
import { GET_USER_LEARNING_ARCHIVE_BY_ID } from '../UserLearningArchive/queries'

const UserSubjects = ({
	match,
	switchCoursesView,
	userRole,
	userRoleType,
	learnerDetails,
	checkAvailableForGroupSubjects,
}) => {
	const history = useHistory()
	const [subjectModal, setSubjectModal] = useState(false)
	const [sessionList, setSessionList] = useState([])
	const [open, setOpen] = useState(false)
	const [initialValues, setInitialValues] = useState({})
	const [performanceMeeting, setPerformanceMeeting] = useState([])
	const userData = JSON.parse(localStorage.getItem('user'))
	const isPerformanceManagement = _.get(userData, 'user.account.performance_management') || false
	const isReviewer = isReviewerRole(userRole, userRoleType)
	const isManager = isManagerRole(userRole, userRoleType)
	const [
		loadManagerMeetings,
		{ data: loadManagerMeetingsData, error: userPerformanceError, loading: userPerformanceLoading },
	] = useLazyQuery(GET_USER_PERFORMANCE_MEETING, {
		variables: { learner_id: match.params.id },
		fetchPolicy: 'network-only',
	})
	const { loading: learningArchiveLoading, error: learningArchiveError, data: learningArchiveData } = useQuery(
		GET_USER_LEARNING_ARCHIVE_BY_ID,
		{
			variables: { id: match.params.id },
			fetchPolicy: 'cache-and-network',
		},
	)
	useEffect(() => {
		loadManagerMeetings()
	}, [])
	useEffect(() => {
		if (loadManagerMeetingsData && loadManagerMeetingsData.meeting) {
			setPerformanceMeeting(
				_.map(loadManagerMeetingsData.meeting.sort(compare('meeting_date')), item => ({
					...item,
					loadManagerMeetings,
				})),
			)
		}
	}, [loadManagerMeetingsData])
	const state = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
	const user = state ? state.user : null
	const panes = [
		{
			menuItem: 'Courses',
			render: () => (
				<Tab.Pane>
					<Table subjects={subjectsByCurrentAccount} />
				</Tab.Pane>
			),
		},
		sessionList.length > 0
			? {
					menuItem: 'Sessions',
					render: () => (
						<Tab.Pane>
							<SessionTable subjects={sessionList} />
						</Tab.Pane>
					),
			  } // eslint-disable-line no-mixed-spaces-and-tabs
			: null,
		performanceMeeting && isPerformanceManagement && isManager
			? {
					menuItem: 'Performance',
					render: () => (
						<Tab.Pane>
							<PerformanceMeeting
								userPerformanceLoading={userPerformanceLoading}
								performanceMeetingData={performanceMeeting}
							/>
							<UserLearningArchive data={learningArchiveData} switchCoursesView={switchCoursesView} />
						</Tab.Pane>
					),
			  } // eslint-disable-line no-mixed-spaces-and-tabs
			: null,
		performanceMeeting && isPerformanceManagement && isManager
			? {
					menuItem: 'Learning Archive',
					render: () => (
						<Tab.Pane>
							<UserLearningArchive data={learningArchiveData} switchCoursesView={switchCoursesView} />
						</Tab.Pane>
					),
			  } // eslint-disable-line no-mixed-spaces-and-tabs
			: null,
	]

	const onAddCourseClick = () => {
		learnerDetails({ selectedUsers: [id] })
		checkAvailableForGroupSubjects(availableForGroupSubjects.length)
		history.push(`${Routes.LERANER_COURSE_BUNDLES}`)
	}
	const closeModal = () => {
		setOpen(false)
	}
	const openModal = () => {
		setInitialValues({ learner_id: id })
		setOpen(true)
	}
	const {
		params: { id },
	} = match

	const { loading, error, data } = useQuery(GET_USER_SUBJECTS_BY_ID, {
		variables: { id },
		fetchPolicy: 'cache-and-network',
	})

	const { data: sessionData, loading: sessionLoading } = useQuery(GET_USER_SESSION_BY_ID, {
		variables: {
			filters: {
				user_id: id,
				account_id: user && user.account ? parseInt(user.account.id) : null,
			},
		},
		fetchPolicy: 'cache-and-network',
	})

	useEffect(() => {
		if (sessionData) {
			const tempArr = sessionData.delegateSessions.data.data.map(d => ({ ...d, listType: 'session' }))
			setSessionList(tempArr)
		}
	}, [sessionData])

	if (loading || learningArchiveLoading || sessionLoading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>
	if (userPerformanceError) return <span>Something went wrong :(</span>
	if (learningArchiveError) return <span>Something went wrong :(</span>

	const { subjectsByCurrentAccount, availableForGroupSubjects } = data.byId

	// const isAssessor = isAssessorRole(userRole, userRoleType)

	return (
		<div className="user-subjects">
			<div className="header">
				<div className="left-part">
					<h3>Learner Activities</h3>
					{/* eslint-disable-next-line max-len */}
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
					<span className="link" onClick={switchCoursesView}>
						View learning archive
					</span>
				</div>
				<div className="performance-flex">
					<div className="left-part">
						{!isReviewer && <Button onClick={openModal} content="Book Meeting" />}
					</div>
					<div className="left-part">
						{!isReviewer && (
							<Button
								onClick={onAddCourseClick}
								disabled={!availableForGroupSubjects.length}
								content="Add course"
							/>
						)}
					</div>
				</div>
			</div>
			<div className="tabs-view">
				<Tab panes={panes} />
			</div>

			{!isReviewer && subjectModal && (
				<AddSubject
					open={subjectModal}
					subjectsList={availableForGroupSubjects}
					onClose={() => setSubjectModal(false)}
				/>
			)}
			{open && (
				<BookMeetingModal
					open={open}
					initialValues={initialValues}
					onClose={closeModal}
					loadManagerMeetings={loadManagerMeetings}
				/>
			)}
		</div>
	)
}

UserSubjects.propTypes = {
	match: PropTypes.object.isRequired,
	switchCoursesView: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
	learnerDetails: PropTypes.func,
	checkAvailableForGroupSubjects: PropTypes.func,
}

UserSubjects.defaultProps = {
	learnerDetails: () => {},
	checkAvailableForGroupSubjects: () => {},
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		learnerDetails: data => getLearnerDetails(data, dispatch),
		checkAvailableForGroupSubjects: data => getAvailableForGroupSubjects(data, dispatch),
	}),
	withRouter(UserSubjects),
)
