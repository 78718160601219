import React from 'react'
import { TextArea } from 'semantic-ui-react'
import classNames from 'classnames'

const Textarea = field => {
	return (
		<div className={classNames('field-wrap', { error: field.error })}>
			{field.label && <span className="field-label">{field.label}</span>}
			<TextArea
				name={field.name}
				value={field.value}
				onChange={field.onChange}
				rows={field.rows}
				placeholder={field.placeholder}
				disabled={field.disabled || false}
				style={{
					width: field.fluid ? '100%' : 'auto',
					resize: field.resize ? 'auto' : 'none',
				}}
			/>
		</div>
	)
}

export default Textarea
