import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'
import { GET_PERFORMANCE_MEETING } from 'Queries/performanceQueries'
import { withContext } from 'Context/index'
import { withToastManager } from 'react-toast-notifications'
import './style.scss'
import PerformanceItemData from './PerformanceItemData'

const PerformanceItem = ({ filter, onFiltered }) => {
	const queryOptions = useMemo(() => {
		return {
			variables: {
				filters: filter,
			},
			fetchPolicy: 'no-cache',
		}
	}, [filter])
	const { data, error, loading } = useQuery(GET_PERFORMANCE_MEETING, queryOptions)
	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>
	return (
		<>
			<div className="users-list">
				<div className="tabs-view">
					<PerformanceItemData data={data} filter={filter} onFiltered={onFiltered} />
				</div>
			</div>
		</>
	)
}

PerformanceItem.propTypes = {
	filter: PropTypes.object.isRequired,
	onFiltered: PropTypes.func.isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
	]) => ({
		// state
		userRole: role,
		userRoleType: roleType,
	}),
	withToastManager(memo(PerformanceItem)),
)
