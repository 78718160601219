import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageMetaTags } from 'Common'
import { useHistory } from 'react-router-dom'
import { Routes } from 'Constants'
import './style.scss'
import { allRoles, isSystemAdminRole } from 'Utils/roles'
import { withContext } from 'Context'
import _ from 'lodash'

const PAGE_NAME = 'Settings'

const Setting = ({ setBreadcrumbs, userRole, userRoleType }) => {
	const history = useHistory()
	const userData = JSON.parse(localStorage.getItem('user'))
	const isSystemAdmin = isSystemAdminRole(userRole, userRoleType)
	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])
	const handleChange = role => {
		console.log('Tag click', role)
		if (role === 'API') history.push(location.pathname + '/api')
		if (role === 'Tags') history.push(location.pathname + '/tags')
		if (role === 'Topics') history.push(Routes.TOPICS)
		if (role === 'LEAVERS_STARTERS_AUTOMATION') history.push(Routes.LEAVERS_STARTERS_AUTOMATION)
		if (role === 'PERFORMANCE') history.push(Routes.SETTING_PERFORMANCE)
	}
	const isPerformanceManagement = _.get(userData, 'user.account.performance_management') || false
	return (
		<div className="setting-list">
			<PageMetaTags title={PAGE_NAME} />
			<h2>{PAGE_NAME}</h2>
			<div
				role="button"
				className="roles"
				onClick={() => handleChange('Topics')}
				onKeyDown={() => handleChange('Topics')}
				tabIndex={0}
			>
				<h4>Topics</h4>
			</div>
			<div
				role="button"
				className="roles padding-top-30"
				onClick={() => handleChange('Tags')}
				onKeyDown={() => handleChange('Tags')}
				tabIndex={0}
			>
				<h4>Tags</h4>
			</div>
			<div
				role="button"
				className="roles padding-top-30"
				onClick={() => handleChange('API')}
				onKeyDown={() => handleChange('API')}
				tabIndex={0}
			>
				<h4>API</h4>
			</div>
			{isSystemAdmin && isPerformanceManagement && (
				<div
					role="button"
					className="roles padding-top-30"
					onClick={() => handleChange('PERFORMANCE')}
					onKeyDown={() => handleChange('PERFORMANCE')}
					tabIndex={0}
				>
					<h4>Performance</h4>
				</div>
			)}
			<div
				role="button"
				className="roles padding-top-30"
				onClick={() => handleChange('LEAVERS_STARTERS_AUTOMATION')}
				onKeyDown={() => handleChange('LEAVERS_STARTERS_AUTOMATION')}
				tabIndex={0}
			>
				<h4>Leavers & Starters Automation</h4>
			</div>
		</div>
	)
}

Setting.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
	]) => ({
		// State
		userRole: role,
		userRoleType: roleType,
	}),
	withBreadcrumbs(Setting),
)
