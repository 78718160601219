import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Icon, Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import { GET_PERFORMANCE_ACCOUNT_AGENDA } from 'Queries/performanceQueries'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import { PageMetaTags } from 'Common'
import _ from 'lodash'
import { isReviewerRole } from 'Utils/roles'
import CreateUpdateAgenda from 'Modals/performance/CreateUpdateAgenda'
import CreateUpdateFrequency from 'Modals/performance/FrequencyModal'
import { withContext } from 'Context/index'
import './style.scss'
import Table from './Table'

const PAGE_NAME = 'Performance'

const AgendaManagement = ({ setBreadcrumbs, userRole, userRoleType, supervisionFrequency, appraisalFrequency }) => {
	const [open, setOpen] = useState(false)
	const [openFrequencyModal, setOpenFrequencyModal] = useState(false)
	const [initialValues, setInitialValues] = useState({})
	const [formattedAgenda, setFormattedAgenda] = useState([])

	const [loadPerformanceAccount, { data, error, loading }] = useLazyQuery(GET_PERFORMANCE_ACCOUNT_AGENDA, {
		fetchPolicy: 'network-only',
	})
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	useEffect(() => {
		loadPerformanceAccount()
	}, [])

	const setAgendaData = agendaData => {
		setFormattedAgenda(
			_.map(agendaData, item => ({
				...item,
				onClick: openModal,
				loadPerformanceAccount,
			})),
		)
	}

	useEffect(() => {
		if (data) {
			const agendaData = data.agenda.sort((a, b) => b.id - a.id)
			setAgendaData(agendaData)
		}
	}, [data])

	const closeModal = () => {
		if (initialValues) {
			setInitialValues({})
		}

		setOpen(false)
	}
	const closeFrequencyModal = () => {
		if (initialValues) {
			setInitialValues({})
		}

		setOpenFrequencyModal(false)
	}

	const openModal = item => {
		if (item) {
			item.is_active && item.is_active === 1 ? (item.is_active = 'true') : (item.is_active = 'false')
			setInitialValues({
				...item,
				supervisionFrequency: supervisionFrequency,
				appraisalFrequency: appraisalFrequency,
			})
		} else {
			setInitialValues({
				supervisionFrequency: supervisionFrequency,
				appraisalFrequency: appraisalFrequency,
			})
		}

		setOpen(true)
	}
	const showFrequencyModal = type => {
		if (type === 'supervision') {
			setInitialValues({
				type: type,
				frequency: supervisionFrequency,
			})
		} else {
			setInitialValues({
				type: type,
				frequency: appraisalFrequency,
			})
		}
		setOpenFrequencyModal(true)
	}
	if (error) return <span>Something went wrong :(</span>

	const isReviewer = isReviewerRole(userRole, userRoleType)

	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<div className="performance">
				<div className="frequency-all-types">
					<div className="frequency-type">
						<p className="agenda-margin">Supervision Frequency - {supervisionFrequency || 0}</p>
						{!isReviewer && (
							<Button type="button" size="small" onClick={() => showFrequencyModal('supervision')}>
								<Icon name="edit outline" style={{ color: 'white' }} />
							</Button>
						)}
					</div>
					<div className="frequency-type">
						<p className="agenda-margin">Appraisal Frequency - {appraisalFrequency || 0}</p>
						{!isReviewer && (
							<Button type="button" size="small" onClick={() => showFrequencyModal('appraisal')}>
								<Icon name="edit outline" style={{ color: 'white' }} />
							</Button>
						)}
						{openFrequencyModal && !isReviewer && (
							<CreateUpdateFrequency
								open={openFrequencyModal}
								initialValues={initialValues}
								onClose={closeFrequencyModal}
							/>
						)}
					</div>
				</div>
				<div className="page-title">
					<h1>Agenda Items</h1>
					<div className="actions">
						{!isReviewer && <Button size="large" content="Add Agenda Item" onClick={() => openModal()} />}
					</div>
				</div>
				{loading ? (
					<Loader active />
				) : (
					<Table agenda={formattedAgenda} openModal={openModal} isReviewer={isReviewer} />
				)}
				{open && !isReviewer && (
					<CreateUpdateAgenda
						open={open}
						initialValues={initialValues}
						onClose={closeModal}
						loadPerformanceAccount={loadPerformanceAccount}
					/>
				)}
			</div>
		</>
	)
}

AgendaManagement.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.string.isRequired,
	supervisionFrequency: PropTypes.number,
	appraisalFrequency: PropTypes.number,
}

AgendaManagement.defaultProps = {
	supervisionFrequency: 0,
	appraisalFrequency: 0,
}
export default withContext(contextData => {
	return {
		userRole: contextData[0].user.user.account.role,
		userRoleType: contextData[0].user.user.account.roleType,
		supervisionFrequency: contextData[0].user.user.account.supervision_frequency,
		appraisalFrequency: contextData[0].user.user.account.appraisal_frequency,
	}
}, withBreadcrumbs(AgendaManagement))
