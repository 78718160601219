import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import PerformanceTable from './PerformanceTable'

const PerformanceItemData = ({ filter, onFiltered, data }) => {
	const userList = useMemo(() => {
		return _.map(data && data.all.data.data, user => ({
			...user,
		}))
	}, [data])

	const setPage = useCallback(
		page => {
			onFiltered({ ...filter, page })
		},
		[onFiltered, filter],
	)

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)

			const newActiveParam = { name: filter.sort_param, order: filter.sort_direction }

			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFiltered({ ...filter, sort_param: newActiveParam.name, sort_direction: newActiveParam.order })
		},
		[filter, onFiltered],
	)
	return (
		<>
			<div className="users-list">
				<PerformanceTable
					userList={userList}
					page={filter.page}
					setPage={setPage}
					perPage={data.all.data.per_page}
					totalCount={data.all.total_count}
					length={data.all.data.total}
					handleSortTable={handleSortTable}
					activeSortParam={{
						name: filter.sort_param || '',
						order: filter.sort_direction || 'asc',
					}}
				/>
			</div>
		</>
	)
}

PerformanceItemData.propTypes = {
	filter: PropTypes.object.isRequired,
	onFiltered: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
}

export default memo(PerformanceItemData)
