import React from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import { isReviewerRole } from 'Utils/roles'
import { withContext } from 'Context/index'

import './style.scss'
import { Loader } from 'semantic-ui-react'
import Table from './Table'

const PerformanceManagement = ({ userRole, userRoleType, performanceMeetingData, userPerformanceLoading }) => {
	const isReviewer = isReviewerRole(userRole, userRoleType)
	let meetingData = []
	performanceMeetingData.forEach(meeting => {
		if (meeting) {
			meetingData.push({
				id: meeting.id,
				meeting_date: meeting.meeting_date,
				type: meeting.type,
				loadManagerMeetings: meeting.loadManagerMeetings,
			})
		}
	})
	return (
		<>
			<div className="performance">
				{userPerformanceLoading ? (
					<Loader active />
				) : (
					<Table performance={meetingData} isReviewer={isReviewer} />
				)}
			</div>
		</>
	)
}

PerformanceManagement.propTypes = {
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.string.isRequired,
	userPerformanceLoading: PropTypes.bool,
	performanceMeetingData: PropTypes.array.isRequired,
}
PerformanceManagement.defaultProps = {
	userPerformanceLoading: false,
}
export default withContext(contextData => {
	return {
		userRole: contextData[0].user.user.account.role,
		userRoleType: contextData[0].user.user.account.roleType,
		supervisionFrequency: contextData[0].user.user.account.supervision_frequency,
		appraisalFrequency: contextData[0].user.user.account.appraisal_frequency,
	}
}, withBreadcrumbs(PerformanceManagement))
