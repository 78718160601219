import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useForm } from 'Hooks'
import { GroupSelect } from 'Common'

const PerformanceFilter = ({ location, onFiltered }) => {
	const {
		due: defaultDue,
		active: defaultActive,
		target_date_set: defaultTargetDateSet,
		progress: defaultProgress,
		signed_off: defaultSignedOff,
	} = queryString.parse(location.search)
	const {
		values: { group },
		handleSubmit,
		handleSelectChange,
		clearValues,
	} = useForm(() => {
		let filterValue = { group, page: 1 }
		onFiltered(filterValue)
	})

	useEffect(() => {
		const defaultValues = [defaultDue, defaultActive, defaultTargetDateSet, defaultProgress, defaultSignedOff]
		if (defaultValues.some(item => typeof item !== 'undefined')) {
			onFiltered({
				group,
				page: 1,
			})
		}
	}, [])

	return (
		<div className="users-filter">
			<span className="custom-label">Filter</span>
			<form onSubmit={handleSubmit}>
				<GroupSelect value={group} onChange={handleSelectChange} selectOnBlur={false} clearable multiple />
				<div className="actions text-right">
					<Button type="button" basic onClick={clearValues}>
						Clear
					</Button>
					<Button type="submit">Submit</Button>
				</div>
			</form>
		</div>
	)
}

PerformanceFilter.propTypes = {
	location: PropTypes.object.isRequired,
	onFiltered: PropTypes.func.isRequired,
}

export default withRouter(PerformanceFilter)
