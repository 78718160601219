import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, Button } from 'semantic-ui-react'

import { withContext } from 'Context/index'
import FormComponent from 'Helpers/form'
import moment from 'moment'
import { useForm } from '../../../hooks'

const ConfirmSignOff = ({ open, onClose, isManager, onConfirmSignOff }) => {
	const [sending, setSending] = useState(false)
	const confirmSignOff = () => {
		setSending(true)
		onConfirmSignOff(completed_date)
	}
	const {
		values: { completed_date },
		handleChange,
	} = useForm(() => {})
	const modalContent = (
		<>
			<p>
				Please make sure you have added all comments and checked for any mistakes as you cannot edit your
				comments once you have signed off this meeting
			</p>
			{isManager && (
				<FormComponent.Date
					label="Completed Date"
					name="completed_date"
					icon="calendar alternate outline"
					dateFormat="DD-MM-YYYY"
					onChange={handleChange}
					placeholder="Enter Completed Date"
					clearable
					clearIcon="close"
					value={completed_date || null}
					minDate={moment()}
					closable
				/>
			)}
			<div className="actions">
				<Button type="button" content="Cancel" disabled={sending} onClick={onClose} />
				<Button type="button" content="Confirm" loading={sending} onClick={confirmSignOff} />
			</div>
		</>
	)
	return (
		<Modal className="create-update-group-modal" open={open} size="tiny" onClose={onClose}>
			<Modal.Header>Confirm Sign Off</Modal.Header>
			<Modal.Content>{modalContent}</Modal.Content>
		</Modal>
	)
}

ConfirmSignOff.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	isManager: PropTypes.bool.isRequired,
	onConfirmSignOff: PropTypes.func.isRequired,
}

ConfirmSignOff.defaultProps = {
	open: false,
	onClose: () => {},
}

export default withContext(
	([
		{
			user: { user },
		},
	]) => ({
		user,
	}),
	ConfirmSignOff,
)
