import gql from 'graphql-tag'

export const GET_PERFORMANCE_ACCOUNT_AGENDA = gql`
	query performance {
		agenda {
			id
			item_title
			item_text
			type
			recurring
			is_active
			note {
				performance_user_booking_id
				performance_account_agenda_id
				learner_comment
				manager_comment
				status
				document_location
			}
		}
	}
`
export const GET_MEETING_AGENDA = gql`
	query performance($meeting_id: Int!) {
		meetingAgenda(meeting_id: $meeting_id) {
			id
			item_title
			item_text
			type
			recurring
			is_active
			note {
				id
				performance_user_booking_id
				performance_account_agenda_id
				learner_comment
				manager_comment
				status
				document_location
			}
		}
	}
`
export const GET_PERFORMANCE_MEETING = gql`
	query performance($filters: PerformanceFilterInput) {
		all(filters: $filters) {
			data {
				data {
					id
					first_name
					last_name
					photo
					created_at
					account {
						key
						id
						is_active
						role
						learner_notifications
					}
					groups {
						key
						id
						name
						role
					}
					lastActive {
						id
						created_at
					}
					performanceUserBooking {
						id
						upcoming_appraisal {
							id
							meeting_date
							type
							completed_date
							learner_sign_off_date
							manager_sign_off_date
						}
						past_appraisal {
							id
							meeting_date
							type
							completed_date
							learner_sign_off_date
							manager_sign_off_date
						}
						upcoming_supervision {
							id
							meeting_date
							type
							completed_date
							learner_sign_off_date
							manager_sign_off_date
						}
						past_supervision {
							id
							meeting_date
							type
							completed_date
							learner_sign_off_date
							manager_sign_off_date
						}
						manager {
							id
							first_name
							last_name
							email
							role
						}
					}
				}
				total
				per_page
			}
			total_count
		}
	}
`
export const GET_ALL_PERFORMANCE_MEETING = gql`
	query performance($filters: PerformanceFilterInput) {
		all(filters: $filters) {
			full_data {
				id
				first_name
				last_name
				photo
				created_at
				account {
					key
					id
					is_active
					role
				}
				groups {
					key
					id
					name
					role
				}
				lastActive {
					id
					created_at
				}
				performanceUserBooking {
					id
					upcoming_appraisal {
						id
						meeting_date
						type
						completed_date
						learner_sign_off_date
						manager_sign_off_date
					}
					past_appraisal {
						id
						meeting_date
						type
						completed_date
						learner_sign_off_date
						manager_sign_off_date
					}
					upcoming_supervision {
						id
						meeting_date
						type
						completed_date
						learner_sign_off_date
						manager_sign_off_date
					}
					past_supervision {
						id
						meeting_date
						type
						completed_date
						learner_sign_off_date
						manager_sign_off_date
					}
					manager {
						id
						first_name
						last_name
						email
						role
					}
				}
			}
			total_count
		}
	}
`
export const GET_USER_PERFORMANCE_MEETING = gql`
	query performance($learner_id: Int!) {
		meeting(learner_id: $learner_id) {
			id
			type
			meeting_date
		}
	}
`
export const GET_LEARNER_PERFORMANCE_MEETING = gql`
	query performance {
		learnerMeeting {
			id
			type
			meeting_date
		}
	}
`
export const GET_MEETING_SIGN_OFF = gql`
	query performance($meeting_id: Int!) {
		meetingSignOff(meeting_id: $meeting_id) {
			id
			type
			meeting_date
			completed_date
			learner_sign_off_date
			manager_sign_off_date
			learner_notes
			manager_notes
			document_location
			user {
				id
				first_name
				last_name
			}
			manager {
				id
				first_name
				last_name
			}
		}
	}
`
