const Routes = {
	// Auth Routes
	LOGIN: '/login',
	REGISTRATION: '/registration',
	RESET_PASSWORD: '/reset-password',
	RESET_PASSWORD_CONFIRM: '/password/reset/:token',
	LOGIN_WITH_TOKEN: '/loginToken/:emailId/:authToken',
	// 2FA Routes
	TWO_FACTOR_AUTH: '/2fa',
	// Dashboard Routes
	DASHBOARD: '/dashboard',
	// Dashboard Routes
	PRINT: '/print',
	// Subjects Routes
	SUBJECTS: {
		path: '/courses',
		fullPath: '/courses/:pivotId?/:sectionId?/:type?/:outcomeId?',
		learningArchive: '/courses/:pivotId?/:sectionId?/learning-archive',
		SUBJECT_SESSION_VIEW: '/course/session/:sessionId/:pivotId',
	},
	ASSESSMENT: {
		path: '/assessment',
		instructions: '/assessment/instructions',
		questions: '/assessment/questions',
	},
	REPORTING: '/reporting',
	LEAVERS_STARTERS_AUTOMATION: '/reporting/leavers-starters-automation',
	SETTING_PERFORMANCE: '/settings/performance',
	LEARNER_PERFORMANCE: '/learner/performance',
	PERFORMANCE: '/performance',
	MEETING_SIGN_OFF: '/meeting/:id/sign-off',
	CERTIFICATES: '/certificates',
	PROFILE: '/profile',
	ACTIVITY: '/activity',
	ACTIVITY_SINGLE_USER: '/activity/:id',
	USERS: '/learners',
	SINGLE_USER: '/learners/:id',
	SESSION_USER: '/learners/:id/session/:sessionId/:pivotId',
	BOOKMARKS: '/bookmarks',
	GROUPS: '/group-manager',
	MANAGER: '/manager',
	MATRIX: '/matrix',
	GROUP_COMPLIANCE: '/group/compliance',
	SETTING: '/settings',
	SETTING_API: '/settings/api',
	TAGS: '/settings/tags',
	TAGS_VIEW: '/settings/tags/:id',
	TAGS_Add_Courses: '/settings/tags/:id/learner',
	LERANER_COURSE_BUNDLES: '/manager/course-bundles',
	LERANER_ADD_COURSES: '/manager/add-courses/:accountSubscriptionId',
	TOPICS: '/settings/topics',
	CREATE_TOPICS: '/settings/topics/create',
	EDIT_TOPICS: '/settings/topics/edit/:id',
	TOPIC_VIEW: '/settings/topics/:id',
	SESSION: {
		path: '/settings/session',
		create: '/settings/session/:id/create',
		view: '/settings/session/:id/view/:sessionId',
		edit: '/settings/session/:id/edit/:sessionId',
		copy: '/settings/session/:id/copy/:sessionId',
	},
	MANAGER_SESSION_VIEW: '/manager/session/:id/view/:sessionId',
	SCORM_LEARNING_IFRAME_EXIT: '/scorm/iframe/exit',
}

export { Routes }
