import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import { PageContent, PageMetaTags } from 'Common'

import './style.scss'
import PerformanceFilter from './PerformanceFilter'
import PerformanceItem from './PerformanceItem'

const PAGE_NAME = 'Performance'

const PerformanceList = ({ setBreadcrumbs }) => {
	const [subjectsFilter, setSubjectFilter] = useState({ page: 1 })

	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<PageContent className="activity-page">
				<PageContent.Content>
					<PerformanceItem filter={subjectsFilter} onFiltered={setSubjectFilter} />
				</PageContent.Content>

				<PageContent.RightBar>
					<PerformanceFilter onFiltered={setSubjectFilter} />
				</PageContent.RightBar>
			</PageContent>
		</>
	)
}

PerformanceList.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(PerformanceList)
