import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import { Button } from 'semantic-ui-react'
import { Table } from 'Common'
import _ from 'lodash'
import { withContext } from '../../../context'
import { getFilteredUsers } from '../../../store/actions/usersActions'
import { allRoles, getRoleNameById, isReviewerRole } from '../../../utils/roles'

const PerformanceTableData = ({ user, userRole, userRoleType }) => {
	const history = useHistory()
	const isReviewer = isReviewerRole(userRole, userRoleType)
	const goToLearnerPerformance = () => {
		history.push(`/learners/${user.id}`)
	}
	const learnerFirstName = (user && user.first_name) || ''
	const learnerLastName = (user && user.last_name) || ''
	const learnerFullName = learnerFirstName + ' ' + learnerLastName || ''
	const managerFirstName =
		(user &&
			user.performanceUserBooking &&
			user.performanceUserBooking.manager &&
			user.performanceUserBooking.manager.first_name) ||
		''
	const managerLastName =
		(user &&
			user.performanceUserBooking &&
			user.performanceUserBooking.manager &&
			user.performanceUserBooking.manager.last_name) ||
		''
	const managerFullName = managerFirstName + ' ' + managerLastName || ''
	const userData = JSON.parse(localStorage.getItem('user'))
	const supervisionFrequency = _.get(userData, 'user.account.supervision_frequency') || 0
	const appraisalFrequency = _.get(userData, 'user.account.appraisal_frequency') || 0

	const role = (user && user.account && user.account && getRoleNameById(user.account.role)) || ''
	const groupName = (user && user && user.groups[0] && user.groups[0].name) || ''

	const lastSupervisionMeetingDate =
		(user &&
			user.performanceUserBooking &&
			user.performanceUserBooking.past_supervision &&
			user.performanceUserBooking.past_supervision.meeting_date) ||
		''
	const upcomingSupervisionMeetingDate =
		(user &&
			user.performanceUserBooking &&
			user.performanceUserBooking.upcoming_supervision &&
			user.performanceUserBooking.upcoming_supervision.meeting_date) ||
		''
	const lastAppraisalMeetingDate =
		user &&
		user.performanceUserBooking &&
		user.performanceUserBooking.past_appraisal &&
		user.performanceUserBooking.past_appraisal.meeting_date
			? user.performanceUserBooking.past_appraisal.meeting_date
			: ''
	const upcomingAppraisalMeetingDate =
		(user &&
			user.performanceUserBooking &&
			user.performanceUserBooking.upcoming_appraisal &&
			user.performanceUserBooking.upcoming_appraisal.meeting_date) ||
		''

	const getFrequencyColor = (value, frequency) => {
		let color = '#b80202'
		if (value !== '' || frequency !== '') {
			// const value = '09/06/2023'

			// Convert the given date to a Moment object
			const givenMoment = moment(value)
			// Get the current date as a Moment object
			const currentMoment = moment()
			const lastPastMeetingInDays = Math.abs(givenMoment.diff(currentMoment, 'days'))
			// Subtract two months from the current date
			const monthsAgo = currentMoment.subtract(frequency, 'months')
			const recurringMeetingInDays = monthsAgo.daysInMonth()
			const nearestPastDays = 5
			// Get the number of days in the last two months
			if (lastPastMeetingInDays <= nearestPastDays) {
				color = '#ff9900'
			} else if (lastPastMeetingInDays <= recurringMeetingInDays) {
				color = '#238823'
			}
		}
		return color
	}

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className={classNames('custom-tr')}>
				<Table.Cell title={learnerFullName}>{learnerFullName}</Table.Cell>
				<Table.Cell title={managerFullName}>{managerFullName}</Table.Cell>
				<Table.Cell title={groupName}>{groupName}</Table.Cell>
				<Table.Cell title={role}>{role}</Table.Cell>
				<Table.Cell
					style={{
						backgroundColor: getFrequencyColor(lastSupervisionMeetingDate, supervisionFrequency),
						color: '#ffffff',
					}}
					title={lastSupervisionMeetingDate}
				>
					{lastSupervisionMeetingDate && moment(lastSupervisionMeetingDate).format('DD/MM/YYYY')}
				</Table.Cell>
				<Table.Cell
					style={{
						backgroundColor: getFrequencyColor(lastSupervisionMeetingDate, supervisionFrequency),
						color: '#ffffff',
					}}
					title={upcomingSupervisionMeetingDate}
				>
					{upcomingSupervisionMeetingDate && moment(upcomingSupervisionMeetingDate).format('DD/MM/YYYY')}
				</Table.Cell>
				<Table.Cell
					style={{
						backgroundColor: getFrequencyColor(lastAppraisalMeetingDate, appraisalFrequency),
						color: '#ffffff',
					}}
					title={lastAppraisalMeetingDate}
				>
					{lastAppraisalMeetingDate && moment(lastAppraisalMeetingDate).format('DD/MM/YYYY')}
				</Table.Cell>
				<Table.Cell
					style={{
						backgroundColor: getFrequencyColor(lastAppraisalMeetingDate, appraisalFrequency),
						color: '#ffffff',
					}}
					title={upcomingAppraisalMeetingDate}
				>
					{upcomingAppraisalMeetingDate && moment(upcomingAppraisalMeetingDate).format('DD/MM/YYYY')}
				</Table.Cell>
				{!isReviewer && (
					<Table.Cell>
						<Button type="button" size="small" content="View" onClick={goToLearnerPerformance} />
					</Table.Cell>
				)}
			</Table.Row>
		</>
	)
}

PerformanceTableData.propTypes = {
	user: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}
export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	PerformanceTableData,
)
