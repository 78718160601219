import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withContext } from 'Context'
import { getFilteredUsers } from 'Actions/usersActions'
import { allRoles, isReviewerRole } from 'Utils/roles'
import { Button } from 'semantic-ui-react'
import moment from 'moment/moment'
import { useHistory } from 'react-router-dom'

const ManagerMeeting = ({ item, userRole, userRoleType }) => {
	const history = useHistory()
	const goToSignOffPage = item => {
		history.push(`/meeting/${item.id}/sign-off/`)
	}
	const isReviewer = isReviewerRole(userRole, userRoleType)
	return (
		<>
			{item && (
				<div className={classNames('performance-item', { 'no-control': isReviewer })}>
					<div className="performance-item-content">
						<div className="meeting-table-body">
							<span title={item.type}>{item.type || '-'}</span>
						</div>
						<div className="meeting-table-body">
							<span title={item.meeting_date}>
								{moment(item.meeting_date).format('DD-MM-YYYY') || '-'}
							</span>
						</div>

						{!isReviewer && (
							<div>
								<Button type="button" size="small" onClick={() => goToSignOffPage(item)}>
									<span>View</span>
								</Button>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	)
}

ManagerMeeting.propTypes = {
	item: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	ManagerMeeting,
)
