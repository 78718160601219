import Dashboard from 'Pages/Dashboard'

import Subjects from 'Pages/Subjects'
import Assessment from 'Pages/Assessment'
import Certificates from 'Pages/Certificates'
import Profile from 'Pages/Profile'
import Activity from 'Pages/Activity'
import Users from 'Pages/Users'
import Bookmarks from 'Pages/Bookmarks'
import Groups from 'Pages/Groups'
import Matrix from 'Pages/Matrix'
import GroupCompliance from 'Pages/Groups/Compliance/'
import Setting from 'Pages/Setting'
import API from 'Pages/Setting/API'
import Tags from 'Pages/Tags'
import TagList from 'Pages/Tags/TagList'
import AddCourses from 'Pages/Tags/AddCourses'
import AddUserCourses from 'Pages/Users/AddUserCourses'
import Topics from 'Pages/Topics'
import Sessions from 'Pages/Sessions'
import SessionDetails from 'Pages/Subjects/SessionDetails'
import ManagerSessionView from 'Pages/Sessions/ManagerSessionView'
import LeaversAndStartersAutomation from 'Pages/Reporting/LeaversAndStartersAutomation'
import Agenda from 'Pages/Performance/Agenda'
import Performance from 'Pages/Performance/PerformanceList'
import LearnerMeeting from 'Pages/Performance/Meeting/LearnerMeeting'
import MeetingSignOff from 'Pages/Performance/Meeting/SignOff'

import {
	allRoles,
	getAllLearnerRoles,
	getAllManagerRoles,
	getAllRolesWithAccount,
	getAllSystemAdminRoles,
	getAssessorRoles,
} from 'Utils/roles'
import { Routes } from './Routes'
import CourseBundle from '../components/pages/Users/AddUserCourses/CourseBundle'

const allManagerRoles = getAllManagerRoles()
const allLearnerRoles = getAllLearnerRoles()
const allSystemAdminRoles = getAllSystemAdminRoles()
const allAccountRoles = getAllRolesWithAccount()
const allAssessorRoles = getAssessorRoles()

const navigationRoutes = [
	{
		name: 'Dashboard',
		path: Routes.DASHBOARD,
		exact: true,
		access: allAccountRoles,
		component: Dashboard,
	},
	{
		name: 'Courses',
		path: Routes.SUBJECTS.fullPath,
		exact: false,
		access: allLearnerRoles,
		component: Subjects,
	},
	{
		name: 'Learner-Performance',
		path: Routes.LEARNER_PERFORMANCE,
		exact: false,
		access: allLearnerRoles,
		component: LearnerMeeting,
	},
	{
		name: 'Learners',
		path: Routes.USERS,
		exact: false,
		access: { ...allAssessorRoles, ...allManagerRoles },
		component: Users,
	},
	{
		name: 'Courses Manager',
		path: `${Routes.MANAGER}/:userId${Routes.SUBJECTS.fullPath}`,
		exact: false,
		access: { ...allAssessorRoles, ...allManagerRoles },
		component: Subjects,
	},
	{
		name: 'Assessment',
		path: Routes.ASSESSMENT.path,
		exact: false,
		access: allLearnerRoles,
		component: Assessment,
	},
	{
		name: 'My Certificates',
		path: Routes.CERTIFICATES,
		exact: true,
		access: allAccountRoles,
		component: Certificates,
	},
	{
		name: 'Group-Compliance',
		path: Routes.GROUP_COMPLIANCE,
		exact: true,
		access: allAccountRoles,
		component: GroupCompliance,
	},
	{
		name: 'Activity',
		path: Routes.ACTIVITY,
		exact: false,
		access: allManagerRoles,
		component: Activity,
	},
	{
		name: 'My Profile',
		path: Routes.PROFILE,
		exact: true,
		access: allRoles,
		component: Profile,
	},
	{
		name: 'Bookmarks',
		path: Routes.BOOKMARKS,
		exact: true,
		access: allLearnerRoles,
		component: Bookmarks,
	},
	{
		name: 'Group Manager',
		path: Routes.GROUPS,
		exact: true,
		access: allManagerRoles,
		component: Groups,
	},
	{
		name: 'Training Matrix',
		path: Routes.MATRIX,
		exact: true,
		access: allAccountRoles,
		component: Matrix,
	},
	{
		name: 'Settings',
		path: Routes.SETTING,
		exact: true,
		access: allSystemAdminRoles,
		component: Setting,
	},
	{
		name: 'Settings/api',
		path: Routes.SETTING_API,
		exact: true,
		access: allSystemAdminRoles,
		component: API,
	},
	{
		name: 'Settings/tags',
		path: Routes.TAGS,
		exact: true,
		access: allSystemAdminRoles,
		component: Tags,
	},
	{
		name: 'Performance',
		path: Routes.PERFORMANCE,
		exact: true,
		access: allSystemAdminRoles,
		component: Performance,
	},
	{
		name: 'SignOff',
		path: Routes.MEETING_SIGN_OFF,
		exact: true,
		access: allAccountRoles,
		component: MeetingSignOff,
	},
	{
		name: 'Settings/performance',
		path: Routes.SETTING_PERFORMANCE,
		exact: true,
		access: allSystemAdminRoles,
		component: Agenda,
	},
	{
		name: 'Settings/taglist',
		path: Routes.TAGS_VIEW,
		exact: true,
		access: allSystemAdminRoles,
		component: TagList,
	},
	{
		name: 'Settings/addCourses',
		path: Routes.TAGS_Add_Courses,
		exact: true,
		access: allSystemAdminRoles,
		component: AddCourses,
	},
	{
		name: 'Learners/courseBundles',
		path: Routes.LERANER_COURSE_BUNDLES,
		exact: true,
		access: { ...allAssessorRoles, ...allManagerRoles },
		component: CourseBundle,
	},
	{
		name: 'Learners/addUserCourses',
		path: Routes.LERANER_ADD_COURSES,
		exact: true,
		access: { ...allAssessorRoles, ...allManagerRoles },
		component: AddUserCourses,
	},
	{
		name: 'Setting/topics',
		path: Routes.TOPICS,
		exact: false,
		access: allSystemAdminRoles,
		component: Topics,
	},
	{
		name: 'Setting/session',
		path: Routes.SESSION.path,
		exact: false,
		access: allSystemAdminRoles,
		component: Sessions,
	},
	{
		name: 'Manager/setting/session/view',
		path: Routes.MANAGER_SESSION_VIEW,
		exact: false,
		access: allManagerRoles,
		component: ManagerSessionView,
	},
	{
		name: 'Subject/session',
		path: Routes.SUBJECTS.SUBJECT_SESSION_VIEW,
		exact: true,
		access: allLearnerRoles,
		component: SessionDetails,
	},
	{
		name: 'Leavers And Starters Automation',
		path: Routes.LEAVERS_STARTERS_AUTOMATION,
		exact: true,
		access: allSystemAdminRoles,
		component: LeaversAndStartersAutomation,
	},
]

export { navigationRoutes }
