import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import { isReviewerRole } from 'Utils/roles'
import { withContext } from 'Context/index'
import { GET_MEETING_SIGN_OFF, GET_MEETING_AGENDA } from 'Queries/performanceQueries'
import '../style.scss'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Button, Grid, Label, Loader } from 'semantic-ui-react'
import moment from 'moment'
import { withToastManager } from 'react-toast-notifications'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { PageMetaTags } from '../../../../common'
import MeetingAgendaItems from './MeetingAgendaItems'
import ConfirmSignOffModal from '../../../../modals/performance/ConfirmSignOffModal'
import { isLearnerRole, isManagerRole } from '../../../../../utils/roles'
import { SAVE_NOTES } from '../../../../../store/mutations/meetingMutations'
import { post } from '../../../../../store/queries/rest/client'

const PAGE_NAME = 'Meeting Sign-Off'
const SignOff = ({ match, userRole, userRoleType, setBreadcrumbs, toastManager }) => {
	const {
		params: { id },
	} = match
	const [meetingData, setMeetingData] = useState({})
	const [activeAgendas, setAgendaData] = useState([])
	const [open, setOpenModal] = useState(false)
	const [agendaComments, setAgendaComments] = useState([])
	const isManager = isManagerRole(userRole, userRoleType)
	const isLearner = isLearnerRole(userRole, userRoleType)
	const [saveMeetingNotes] = useMutation(SAVE_NOTES)
	const [submitting, setSubmitting] = useState(false)
	const [managerNotes, setManagerNotes] = useState('')
	const [learnerNotes, setLearnerNotes] = useState('')
	const fileInputRef = useRef(null)
	const [loadMeetingSignOff, { data, error, loading }] = useLazyQuery(GET_MEETING_SIGN_OFF, {
		variables: {
			meeting_id: id,
		},
		fetchPolicy: 'network-only',
	})
	const [loadAccountAgenda, { data: agendaData, error: agendaError, loading: agendaLoading }] = useLazyQuery(
		GET_MEETING_AGENDA,
		{
			variables: {
				meeting_id: id,
			},
			fetchPolicy: 'no-cache',
		},
	)
	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])
	useEffect(() => {
		loadAccountAgenda()
		loadMeetingSignOff()
	}, [])

	useEffect(() => {
		if (data) {
			setMeetingData(data.meetingSignOff)
		}
	}, [data])
	useEffect(() => {
		if (agendaData && meetingData) {
			let activeAgendaData = agendaData.meetingAgenda.filter(res => meetingData.type === res.type)
			activeAgendaData = activeAgendaData.map(res => {
				if (!res.note) {
					res.note = { manager_comment: '', learner_comment: '' }
				}
				return res
			})
			setAgendaData(activeAgendaData)
		}
	}, [agendaData, meetingData])
	const closeModal = () => {
		setOpenModal(false)
	}
	const openModal = () => {
		setOpenModal(true)
	}
	useEffect(() => {
		setManagerNotes(meetingData.manager_notes)
		setLearnerNotes(meetingData.learner_notes)
	}, [meetingData])
	const updatedAgendaComments = updatedAgendas => {
		let payloadAgendaData = []
		updatedAgendas.forEach(res => {
			payloadAgendaData.push({
				id: res.id || 0,
				note: res.note || null,
			})
		})
		setAgendaComments(payloadAgendaData)
	}
	const handleNoteChange = (type, value) => {
		if (type === 'manager_note') {
			setManagerNotes(value)
		} else {
			setLearnerNotes(value)
		}
	}
	const handleDocsChange = e => {
		const body = new FormData()
		const file = e.target.files[0]
		body.append('import_meeting_docs', file)
		body.append('meeting_id', meetingData.id)
		setSubmitting(true)
		if (typeof e.target.files[0] !== 'undefined') {
			post('/import-meeting-docs', body)
				.then(response => {
					return response.json()
				})
				.then(() => {
					toastManager.add('File Uploaded Successfully', { appearance: 'success', autoDismiss: true })
					setSubmitting(false)
				})
		}
		if (fileInputRef.current) {
			fileInputRef.current.value = null
		}
	}
	const onConfirmSignOff = completedDate => {
		const agendaCommentsData = agendaComments.length > 0 ? agendaComments : activeAgendas
		let payloadAgendaData = []
		agendaCommentsData.forEach(res => {
			payloadAgendaData.push({
				id: res.id || 0,
				note: res.note || null,
			})
		})
		const variables = {
			payload: {
				meeting_id: meetingData.id,
				agendaComments: payloadAgendaData,
				learner_notes: learnerNotes,
				manager_notes: managerNotes,
				completed_date: completedDate,
				isManager,
			},
		}
		saveMeetingNotes({ variables })
			.then(() => {
				loadMeetingSignOff({
					variables: {
						meeting_id: meetingData.id,
					},
				})
				loadAccountAgenda({
					variables: {
						meeting_id: meetingData.id,
					},
				})
			})
			.finally(() => {
				closeModal()
			})
	}
	const downloadUploadedDocument = () => {
		if (meetingData.document_location !== '') {
			const S3URL = `${process.env.REACT_APP_S3_URL}`
			const AWS_BUCKET = `${process.env.REACT_APP_AWS_BUCKET}`
			const filePath = `${S3URL}${AWS_BUCKET}${meetingData.document_location}`
			window.open(filePath, '_blank')
		}
	}
	if (error) return <span>Something went wrong :(</span>
	if (agendaError) return <span>Something went wrong :(</span>
	const isReviewer = isReviewerRole(userRole, userRoleType)
	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<div className="performance">
				{loading || submitting || agendaLoading ? (
					<Loader active />
				) : (
					<div className="meeting-signoff-header">
						<div className="meeting-signoff-item">
							<div className="meeting-agenda-header">
								<h5>Employee:</h5>
								{meetingData && meetingData.user && (
									<p>
										{meetingData.user.first_name} {meetingData.user.last_name}
									</p>
								)}
							</div>
							<div className="meeting-agenda-header">
								<h5>Type:</h5>
								{meetingData && <p>{meetingData.type}</p>}
							</div>
							<div className="meeting-agenda-header">
								<h5>Date Meeting Held On:</h5>
								{meetingData && meetingData.completed_date ? (
									<p>{moment(meetingData.completed_date).format('DD-MM-YYYY')}</p>
								) : (
									'--'
								)}
							</div>
						</div>
						<div className="meeting-signoff-item">
							<div className="meeting-agenda-header">
								<h5>Manager:</h5>
								{meetingData && meetingData.manager && (
									<p>
										{meetingData.manager.first_name} {meetingData.manager.last_name}
									</p>
								)}
							</div>
							<div className="meeting-agenda-header">
								<h5>Date Meeting Planned:</h5>
								{meetingData && meetingData.meeting_date ? (
									<p>{moment(meetingData.meeting_date).format('DD-MM-YYYY')}</p>
								) : (
									'--'
								)}
							</div>
							<div className="meeting-agenda-header">
								<h5>Signed Off:</h5>
								{meetingData && (
									<p>
										{isManager && meetingData.manager_sign_off_date
											? moment(meetingData.manager_sign_off_date).format('DD-MM-YYYY')
											: isLearner && meetingData.learner_sign_off_date
											? moment(meetingData.learner_sign_off_date).format('DD-MM-YYYY')
											: '--'}
									</p>
								)}
							</div>
						</div>
					</div>
				)}
				{loading || submitting || agendaLoading ? (
					<Loader active />
				) : (
					<div>
						<h1 className="meeting-agenda-summary">Summary</h1>
						<ul>
							{activeAgendas.length > 0 &&
								activeAgendas.map(agenda => (
									<li>
										<p>{agenda.item_title || '--'}</p>
									</li>
								))}
						</ul>
						<div className="meeting-agenda-width">
							<hr />
						</div>
					</div>
				)}
				<div className="page-title page-title-margin">
					{loading || agendaLoading ? (
						<Loader active />
					) : (
						<MeetingAgendaItems
							meetingData={meetingData}
							isReviewer={isReviewer}
							activeAgendas={activeAgendas}
							userRole={userRole}
							userRoleType={userRoleType}
							onUpdateAgendaComments={updatedAgendaComments}
						/>
					)}
				</div>
				{loading || agendaLoading ? (
					<Loader active />
				) : (
					<div className="meeting-agenda-width">
						<hr />
						<h1 className="meeting-agenda-summary">Additional Notes</h1>
					</div>
				)}
				{loading || agendaLoading ? (
					<Loader active />
				) : (
					<div className="meeting-agenda-width meeting-agenda-flex">
						<Label className="meeting-notes-label">Learner Notes</Label>
						<ReactQuill
							theme="snow"
							value={learnerNotes}
							placeholder="Employee Notes"
							readOnly={isManager}
							style={{
								backgroundColor: isLearner ? 'white' : 'transparent',
								cursor: isLearner ? 'auto' : 'not-allowed',
							}}
							onChange={note => handleNoteChange('learner_note', note)}
						/>
						<Label className="meeting-notes-label">Manager Notes</Label>
						<ReactQuill
							theme="snow"
							value={managerNotes}
							placeholder="Manager Notes"
							readOnly={isLearner}
							style={{
								backgroundColor: isManager ? 'white' : 'transparent',
								cursor: isManager ? 'auto' : 'not-allowed',
							}}
							onChange={note => handleNoteChange('manager_note', note)}
						/>
						<Grid.Row>
							<Grid.Column computer={16}>
								<Grid.Row className="users-list">
									<div className="field-wrap meeting-upload-docs">
										<span className="meeting-notes-label">Upload Document</span>
										<label className="upload" htmlFor="Upload">
											<input
												name="upload"
												id="upload"
												type="file"
												ref={fileInputRef}
												disabled={
													submitting ||
													(isManager &&
														!!(meetingData && meetingData.manager_sign_off_date)) ||
													(isLearner && !!(meetingData && meetingData.learner_sign_off_date))
												}
												accept=".csv, text/csv,.xlsx,.xls,image/*,.doc, .docx,.ppt"
												onChange={handleDocsChange}
											/>
											<span className="upload-custom"></span>
										</label>
									</div>
								</Grid.Row>
							</Grid.Column>
						</Grid.Row>
						{meetingData.document_location !== '' && (
							<div className="meeting-btn">
								<Button className="meeting-dwnlod-btn" type="button" onClick={downloadUploadedDocument}>
									Download Document
								</Button>
								<div>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										stroke="#cdcbcb"
									>
										<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
										<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
										<g id="SVGRepo_iconCarrier">
											<path
												d="M12 12V19
              M12 19L9.75 16.6667
              M12 19L14.25 16.6667
              M6.6 17.8333C4.61178 17.8333 3 16.1917 3 14.1667
              C3 12.498 4.09438 11.0897 5.59198 10.6457
              C5.65562 10.6268 5.7 10.5675 5.7 10.5
              C5.7 7.46243 8.11766 5 11.1 5
              C14.0823 5 16.5 7.46243 16.5 10.5
              C16.5 10.5582 16.5536 10.6014 16.6094 10.5887
              C16.8638 10.5306 17.1284 10.5 17.4 10.5
              C19.3882 10.5 21 12.1416 21 14.1667
              C21 16.1917 19.3882 17.8333 17.4 17.8333"
												stroke="#161618"
												strokeLinecap="round"
												strokeLinejoin="round"
											></path>
										</g>
									</svg>
								</div>
							</div>
						)}
						<div className="meeting-sign-off-btn">
							<Button
								style={{
									cursor:
										(isManager && !!(meetingData && meetingData.manager_sign_off_date)) ||
										(isLearner && !!(meetingData && meetingData.learner_sign_off_date))
											? 'not-allowed'
											: 'auto',
								}}
								content="Sign off"
								onClick={openModal}
								disabled={
									submitting ||
									(isManager && !!(meetingData && meetingData.manager_sign_off_date)) ||
									(isLearner && !!(meetingData && meetingData.learner_sign_off_date))
								}
							/>
						</div>
					</div>
				)}
			</div>
			{open && !isReviewer && (
				<ConfirmSignOffModal
					open={open}
					isManager={isManager}
					onConfirmSignOff={onConfirmSignOff}
					onClose={closeModal}
				/>
			)}
		</>
	)
}

SignOff.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.string.isRequired,
	toastManager: PropTypes.object.isRequired,
}
export default withContext(contextData => {
	return {
		userRole: contextData[0].user.user.account.role,
		userRoleType: contextData[0].user.user.account.roleType,
	}
}, withBreadcrumbs(withToastManager(SignOff)))
