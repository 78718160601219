import gql from 'graphql-tag'

export const UPDATE_CREATE_FREQUENCY = gql`
	mutation performance($payload: FrequencyInputs!) {
		updateFrequency(payload: $payload) {
			id
			supervision_frequency
			appraisal_frequency
		}
	}
`
