import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
// import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withContext } from 'Context/index'
import { GET_LEARNER_PERFORMANCE_MEETING } from 'Queries/performanceQueries'
import _ from 'lodash'
import { Loader } from 'semantic-ui-react'

import PerformanceManagement from './index'
import { compare } from '../../../../utils'

const LearnerMeeting = () => {
	const [learnerMeeting, setLearnerMeetingData] = useState([])
	const [
		loadLearnerMeetings,
		{ data: loadLearnerMeetingsData, error: userPerformanceError, loading: userPerformanceLoading },
	] = useLazyQuery(GET_LEARNER_PERFORMANCE_MEETING, {
		fetchPolicy: 'no-cache',
	})
	const setMeeting = meeting => {
		// Build performance tree
		if (meeting) {
			setLearnerMeetingData(
				_.map(meeting.sort(compare('meeting_date')), item => ({
					...item,
					loadLearnerMeetings,
				})),
			)
		}
	}
	useEffect(() => {
		loadLearnerMeetings()
	}, [])
	useEffect(() => {
		if (loadLearnerMeetingsData) {
			setMeeting(loadLearnerMeetingsData.learnerMeeting)
		}
	}, [loadLearnerMeetingsData])

	if (userPerformanceError) return <span>Something went wrong :(</span>

	return (
		<div className="user-subjects">
			<div className="header">
				<div className="left-part">
					<h3 className="margin-top">Performance</h3>
					{userPerformanceLoading ? (
						<Loader active />
					) : (
						<PerformanceManagement performanceMeetingData={learnerMeeting} />
					)}
				</div>
			</div>
		</div>
	)
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
	]) => ({
		userRole: role,
		userRoleType: roleType,
	}),
	withRouter(LearnerMeeting),
)
