import React from 'react'
import PropTypes from 'prop-types'
import { PageContent } from 'Common'
import AgendaItem from './AgendaItem'

const Table = ({ agenda, isReviewer }) => {
	if (!agenda.length) return <PageContent.Message message="List is empty" />

	return (
		<div>
			<div className="list-header">
				{!isReviewer && <div />}
				<div />
				<div>
					<span className="agenda-table-header">Item Title</span>
					<span className="agenda-table-header">Item Text</span>
					<span className="agenda-table-header">Type</span>
					{/*<span className="agenda-table-header">Recurring</span>*/}
					<span className="agenda-table-header">Active</span>
					<span className="agenda-table-header"></span>
				</div>
			</div>
			{agenda.length > 0 && agenda.map(item => <AgendaItem key={item.id} item={item} />)}
		</div>
	)
}

Table.propTypes = {
	agenda: PropTypes.array.isRequired,
	isReviewer: PropTypes.bool.isRequired,
}

export default Table
