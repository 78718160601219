import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withContext } from 'Context'
import { getFilteredUsers } from 'Actions/usersActions'
import { allRoles, isReviewerRole } from 'Utils/roles'
import { Button, Icon } from 'semantic-ui-react'

const AgendaItem = ({ item, userRole, userRoleType }) => {
	const isReviewer = isReviewerRole(userRole, userRoleType)
	return (
		<>
			{item && (
				<div className={classNames('performance-item', { 'no-control': isReviewer })}>
					<div className="agenda-performance-item-content">
						<span className="agenda-table-body" title={item.item_title}>
							{item.item_title || '-'}
						</span>
						<span className="agenda-table-body" title={item.item_text}>
							{item.item_text || '-'}
						</span>
						<span className="agenda-table-body" title={item.type}>
							{item.type || '-'}
						</span>
						{/*<span className="agenda-table-body" title={item.recurring}>*/}
						{/*	{item.recurring || '-'}*/}
						{/*</span>*/}
						<span className="agenda-table-body" title={item.is_active}>
							{item.is_active === 1 ? 'Yes' : 'No'}
						</span>

						{!isReviewer && (
							<Button type="button" size="small" onClick={() => item.onClick(item)}>
								<Icon name="edit outline" style={{ color: 'white' }} />
								<span>Edit</span>
							</Button>
						)}
					</div>
				</div>
			)}
		</>
	)
}

AgendaItem.propTypes = {
	item: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	AgendaItem,
)
