import React, { useState } from 'react'
import './style.scss'
import { Button, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { userGroupRoles, userRoles } from 'Constants'
import { UserPhoto } from 'Common'
import { EXPORT_LEARNING_TIME_TRACKING_ACTIVITY } from 'Mutations/exportMutations'
import useExport from 'Hooks/useExport'
//import { downloadAllCertificate } from 'Queries/rest/certificate'
import { withToastManager } from 'react-toast-notifications'
import { get } from '../../../../store/queries/rest/client'

const UserCard = ({
	user,
	openProfileModal,
	openStatusModal,
	isReviewer,
	isAssessor,
	openAssessorModal,
	toastManager,
}) => {
	const [exportLearningTimeTracking, exportLoading] = useExport(EXPORT_LEARNING_TIME_TRACKING_ACTIVITY, {
		learner_id: user.id,
	})

	const dc = id => {
		setSubmitting(true)
		let megParamone = 'Your certificates are ready. Please check '
		let megParamtwo = 'your email for a link to download them.'
		let susmsg = megParamone + megParamtwo
		get(`/certificate-list/${id}`)
			.then(msg => {
				console.log(msg.status)
				if (msg.status == 200) {
					toastManager.add(susmsg, { appearance: 'success', autoDismiss: true })
				} else {
					let err = 'No certificates to download.'
					toastManager.add(err, { appearance: 'success', autoDismiss: true })
				}
			})
			.catch(error => {
				toastManager.add(error, { appearance: 'success', autoDismiss: true })
			})
			.finally(() => {
				setSubmitting(false)
			})
	}
	const dna = 'Download all certificates'
	const [sub, setSubmitting] = useState(false)
	const groupName = _.get(user, 'groups[0].name')
	const userCurrentAccount = user && user.currentAccount && user.currentAccount.name
	const managerFirstName = user && user.account && user.account.manager && user.account.manager.first_name
	const managerLastName = user && user.account && user.account.manager && user.account.manager.last_name
	const userFirstName = user && user.first_name
	const userLastName = user && user.last_name
	const userRole = groupName
		? _.find(userGroupRoles, ['id', user.groups[0].role])
		: _.find(userRoles, ['id', user.currentAccount.role])

	return (
		<div className="user-card">
			<div className="text-center">
				<UserPhoto className="user-photo" user={user} size="tiny" />
				<span className="user-name">{`${userFirstName || '-'} ${userLastName || '-'}`}</span>
			</div>
			<div>
				<div className="info-item">
					<span className="custom-label">Role</span>
					<span>{userRole && userRole.role}</span>
				</div>
				<div className="info-item">
					<span className="custom-label">Email</span>
					<span>{user.email}</span>
				</div>
				<div className="info-item user-card-manager">
					<span className="custom-label">Manager</span>
					<span>{`${managerFirstName || '-'} ${managerLastName || '-'}`}</span>
				</div>
			</div>
			<div>
				<div className="info-item user-account">
					<span className="custom-label">Account</span>
					<span>{userCurrentAccount || '-'}</span>
				</div>
				{groupName && (
					<div className="info-item">
						<span className="custom-label">Group</span>
						<span>{groupName}</span>
					</div>
				)}
			</div>
			<div className="actions">
				{!isReviewer && !isAssessor && (
					<>
						<Button onClick={openProfileModal}>Edit profile</Button>
						<Button onClick={openStatusModal} className="reverse">
							Change Status
						</Button>
						<Popup
							hoverable
							trigger={
								<Button onClick={openAssessorModal} className="reverse">
									Allocate Assessor
								</Button>
							}
						>
							The Allocate Assessor option allows you to enter the email address for an assessor who is
							not part of your Click account. The assessor will then be able to see the the learner&apos;s
							courses and work with them to complete and sign off their courses. Please note the assessor
							must have their own account set up on Click and be set up with rights as an assessor. Please
							refer to the help centre for more information and support with assessors access.
						</Popup>
						<Button
							type="button"
							loading={exportLoading}
							className="mo"
							content="Export learning time tracking"
							onClick={exportLearningTimeTracking}
						/>
						<Button content={dna} className="ra" disabled={sub} loading={sub} onClick={() => dc(user.id)} />
					</>
				)}
			</div>
		</div>
	)
}

UserCard.propTypes = {
	user: PropTypes.object.isRequired,
	openProfileModal: PropTypes.func.isRequired,
	openStatusModal: PropTypes.func.isRequired,
	openAssessorModal: PropTypes.func.isRequired,
	isReviewer: PropTypes.bool.isRequired,
	isAssessor: PropTypes.bool,
	toastManager: PropTypes.object.isRequired,
}

UserCard.defaultProps = {
	isAssessor: false,
}
//export default UserCard
export default withToastManager(UserCard)
