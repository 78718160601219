import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import { Label } from 'semantic-ui-react'
import { isLearnerRole, isManagerRole } from '../../../../../utils/roles'
import { withContext } from '../../../../../context'
import withBreadcrumbs from '../../../../common/Breadcrumbs/withBreadcrumbs'

const MeetingAgendaItem = ({ index, agendaItem, onCommentUpdate, userRole, userRoleType }) => {
	const [meetingAgendaData, setMeetingAgendaData] = useState({ manager_comment: '', learner_comment: '' })
	const isManager = isManagerRole(userRole, userRoleType)
	const isLearner = isLearnerRole(userRole, userRoleType)

	useEffect(() => {
		setMeetingAgendaData((agendaItem && agendaItem.note) || { manager_comment: '', learner_comment: '' })
	}, [])
	const handleCommentChange = (type, value) => {
		onCommentUpdate(index, type, value)
	}
	return (
		<>
			<Label className="meeting-notes-label">Learner Comments</Label>
			<ReactQuill
				theme="snow"
				value={(meetingAgendaData && meetingAgendaData.learner_comment) || ''}
				placeholder="Employee Comments"
				readOnly={isManager}
				style={{
					backgroundColor: isLearner ? 'white' : 'transparent',
					cursor: isLearner ? 'auto' : 'not-allowed',
				}}
				onChange={comment => handleCommentChange('learner_comment', comment)}
			/>
			<Label className="meeting-notes-label">Manager Comments</Label>
			<ReactQuill
				theme="snow"
				value={(meetingAgendaData && meetingAgendaData.manager_comment) || ''}
				placeholder="Manager Comments"
				style={{
					backgroundColor: isManager ? 'white' : 'transparent',
					cursor: isManager ? 'auto' : 'not-allowed',
				}}
				onChange={comment => handleCommentChange('manager_comment', comment)}
			/>
		</>
	)
}

MeetingAgendaItem.propTypes = {
	agendaItem: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	onCommentUpdate: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.string.isRequired,
}

export default withContext(contextData => {
	return {
		userRole: contextData[0].user.user.account.role,
		userRoleType: contextData[0].user.user.account.roleType,
	}
}, withBreadcrumbs(MeetingAgendaItem))
