import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Button } from 'semantic-ui-react'
import _ from 'lodash'

import { UPDATE_CREATE_AGENDA } from 'Mutations/performanceMutations'
import { useForm } from 'Hooks'

import FormComponent from 'Helpers/form'

const validate = values => {
	const errors = {}
	const requiredFields = ['item_title']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	return errors
}

const CreateUpdateAgenda = ({ open, initialValues, onClose, activeIds, loadPerformanceAccount, query }) => {
	const [sending, setSending] = useState(false)
	const [updateCreateAgenda] = useMutation(UPDATE_CREATE_AGENDA)
	const disabledFields = (initialValues && initialValues.meetings && initialValues.meetings.length > 0) || false
	let {
		values,
		values: { item_title, item_text, type, recurring, is_active },
		errors,
		handleSubmit,
		handleChange,
		handleSelectChange,
		setValues,
	} = useForm(
		() => {
			setSending(true)
			const variables = {
				payload: {
					item_title,
					item_text,
					type,
					recurring,
					is_active,
				},
			}

			if (initialValues && initialValues.id) {
				variables.payload.id = initialValues.id
			}

			updateCreateAgenda({ variables })
				.then(() => {
					loadPerformanceAccount({
						variables: {
							with_child: activeIds,
							search: query,
						},
					})
				})
				.finally(() => {
					setSending(false)
					onClose()
				})
		},
		validate,
		initialValues,
	)
	const typeOptions = [
		{
			key: 'Appraisal',
			value: 'Appraisal',
			text: 'Appraisal',
		},
		{
			key: 'Supervision',
			value: 'Supervision',
			text: 'Supervision',
		},
	]
	const activeOptions = [
		{
			key: 'true',
			value: 'true',
			text: 'Yes',
		},
		{
			key: 'false',
			value: 'false',
			text: 'No',
		},
	]
	const recurringOptions = []
	for (let i = 1; i <= 24; i++) {
		recurringOptions.push({
			key: i,
			value: i,
			text: i,
		})
	}

	useEffect(() => {
		if (type === 'Appraisal') {
			recurring = initialValues.appraisalFrequency || 0
		} else if (type === 'Supervision') {
			recurring = initialValues.supervisionFrequency || 0
		}
		setValues({ ...values, recurring })
	}, [type])
	const handlingErrors = !_.isEmpty(initialValues)
	console.log('handlingErrors', handlingErrors)
	const modalContent = (
		<form onSubmit={handleSubmit}>
			<FormComponent
				customlabel="Item Title"
				name="item_title"
				value={item_title || ''}
				onChange={handleChange}
				placeholder="Enter Item Title"
				error={errors.item_title}
				disabled={disabledFields}
			/>
			<FormComponent.Textarea
				rows={6}
				repeats
				fluid
				label="Item Text"
				name="item_text"
				value={item_text || ''}
				onChange={handleChange}
				error={errors.item_text}
				disabled={disabledFields}
			/>
			<FormComponent.Dropdown
				label="Type"
				name="type"
				options={typeOptions}
				onChange={handleSelectChange}
				icon="angle down"
				selection
				selectOnBlur={false}
				error={errors.type}
				fluid
				value={type || null}
				disabled={disabledFields}
			/>
			{/*<FormComponent.Dropdown*/}
			{/*	label="Recurring"*/}
			{/*	name="recurring"*/}
			{/*	options={recurringOptions}*/}
			{/*	onChange={handleSelectChange}*/}
			{/*	icon="angle down"*/}
			{/*	selection*/}
			{/*	selectOnBlur={false}*/}
			{/*	error={errors.recurring}*/}
			{/*	sort={false}*/}
			{/*	fluid*/}
			{/*	value={recurring || null}*/}
			{/*	disabled={disabledFields}*/}
			{/*/>*/}
			<FormComponent.Dropdown
				label="Active"
				name="is_active"
				options={activeOptions}
				onChange={handleSelectChange}
				icon="angle down"
				selection
				selectOnBlur={false}
				error={errors.is_active}
				fluid
				value={is_active || null}
			/>
			<div className="actions">
				<Button type="button" content="Cancel" disabled={sending} onClick={onClose} />
				<Button type="submit" content="Save" loading={sending} />
			</div>
		</form>
	)

	return (
		<Modal className="create-update-group-modal" open={open} size="tiny" onClose={onClose}>
			<Modal.Header>{`${initialValues.id ? 'Edit' : 'Add'} Agenda`}</Modal.Header>
			<Modal.Content>{modalContent}</Modal.Content>
		</Modal>
	)
}

CreateUpdateAgenda.propTypes = {
	open: PropTypes.bool,
	initialValues: PropTypes.object,
	onClose: PropTypes.func,
	loadPerformanceAccount: PropTypes.func.isRequired,
	activeIds: PropTypes.arrayOf(PropTypes.number),
	query: PropTypes.string,
}

CreateUpdateAgenda.defaultProps = {
	open: false,
	initialValues: {},
	activeIds: null,
	query: null,
	onClose: () => {},
}

export default CreateUpdateAgenda
