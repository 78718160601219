import gql from 'graphql-tag'

export const UPDATE_CREATE_AGENDA = gql`
	mutation performance($payload: AgendaInputs!) {
		updateOrCreateAgenda(payload: $payload) {
			id
			item_title
			item_text
			type
			recurring
			is_active
		}
	}
`

export const DELETE_GROUP = gql`
	mutation groups($id: Int!) {
		delete(id: $id)
	}
`
